import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect, useState } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)


export default function FeatureSectionCuautlaCopy({ setCampus, setIsOpen }) {
    const [imagenes, setImagenes] = useState([]);

    useEffect(() => {
        const cargarImagen = async (nombre) => {
            try {
                const response = await fetch(`http://104.237.128.187:3001/api/uploads/imageCampus/${nombre}`, {
                    method: 'GET'
                });
                
                if (response.ok) {
                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    setImagenes((imagenes) => [...imagenes, { [nombre]: imageUrl }]);
                    console.log(imagenes)
                }
            } catch (err) {
                console.error('Error cargando imagen:', err);
            }
        };

        // Solo cargamos 2 imágenes
        Promise.all([
            cargarImagen('web4.jpg'),
            cargarImagen('web5.jpg')
        ]);

        // Cleanup function para liberar URLs
        return () => {
            imagenes.forEach(url => URL.revokeObjectURL(url));
        };
    }, []);
    return (
        <div className="overflow-hidden bg-gradient-to-b3 h-auto sm:h-auto lg:h-[100vh] md:h-[100vh] w-[100vw] py-10" id='campusBuga'>

            <div className=' w-auto h-full mx-6 lg:mx-6 md:flex sm:hidden lg:flex hidden flex-row'>

                <div className='w-1/6 lg:w-1/6 md:w-0 sm:w-0'>
                </div>
                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-col'>
                    <div className=' h-full p-3 relative'>
                        <img
                            src={imagenes.find(img => Object.keys(img)[0] === `web4.jpg`)?.[`web4.jpg`] || ""}
                            className="w-full h-full imageGlow2"
                        />
                        <Link onClick={() => {
                            setCampus('Cuautla')
                            setIsOpen(true)
                        }}  className="absolute top-3 left-3 w-0 h-0 border-r-[100px] border-r-transparent border-t-[100px] border-t-black hover:scale-110 transform transition duration-300 ease-in-out">
                        </Link>
                        <Link onClick={() => {
                            setCampus('Cuautla')
                            setIsOpen(true)
                        }}  className='absolute top-5 left-7 text-gray-300 italic'>Saber</Link>
                        <Link onClick={() => {
                            setCampus('Cuautla')
                            setIsOpen(true)
                        }}  className='absolute top-9 left-7 text-gray-300 italic'>Más</Link>
                    </div>
                </div>

                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-row'>

                    <div className=' w-full flex flex-col-reverse'>
                        <div className=' h-3/6 py-3'>
                            <MapsTemplate campus={"cuautla"} />
                        </div>
                        <div className=' h-2/6 imageGlow2'>
                            <img src={imagenes.find(img => Object.keys(img)[0] === `web5.jpg`)?.[`web5.jpg`] || ""} alt=""
                                className='h-full p-0 w-full' />
                        </div>
                        <div className=' h-1/6 grid items-center'>
                            <div>

                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Morelos</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Cuautla</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Movil */}
            <div className=' w-auto h-full mx-6 lg:mx-6 md:hidden sm:flex lg:hidden flex flex-col'>
                <div>

                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Morelos</h2>
                    <p className="mt-0 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Cuautla</p>
                    
                </div>
                <Link onClick={() => {
                            setCampus('Cuautla')
                            setIsOpen(true)
                        }}   className='mt-3 p-2 bg-gray-900 text-white'>Saber más</Link>
                <div className='py-3'>
                    <img
                        src={imagenes.find(img => Object.keys(img)[0] === `web4.jpg`)?.[`web4.jpg`] || ""}
                        alt="Product screenshot"
                        className="w-full h-full imageGlow2"

                    />
                </div>
                <div className='w-full h-80'>
                    <MapsTemplate campus={"cuautla"} />
                </div>
                
            </div>
        
        </div>
    )
}
