import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { ExclamationTriangleIcon, HomeModernIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { FaFacebook, FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa'

export default function ModalTemplate({ isOpen, setIsOpen, campus="Centro" }) {

    const infoPorCampus = {
        Bugambilias: {
            telefono: "+525521048568",
            descripcion: "Ubicado en los límites de Cuernavaca y Jiutepec, a máximo 3 minutos de distancia en carro y 10 minutos caminando, encontrarás centros comerciales, restaurantes, gimnasios, salones de belleza, cines, supermercados, casas de construcción, parques, biblioteca, etc. Al ubicarnos en una esquina, contamos con 2 calles, una principal de alto flujo vehicular en donde precisamente frente al Campus hace parada la Ruta 14 en ambos sentidos, y otra de mínimo tránsito, por lo que cuenta con bastante lugar para estacionarse, a su vez, existen cámaras de seguridad vecinales que abarcan 4 manzanas en la periferia de Larghetto, igualmente el Campus cuenta con 6 cámaras de seguridad hacia el exterior y 32 cámaras de seguridad al interior, así como alarma vecinal y 9 micrófonos grabadores de alta calidad. Disfruta de áreas verdes, árboles frutales, áreas de descanso y sociales, salones de gran espacio, equipados con aire acondicionado, tratamiento acústico en pisos y paredes, Smart tv, ventanales que otorgan gran iluminación natural, a su vez, dentro del campus disfruta de juegos de mesa, futbolito de salón, y por supuesto nuestros impresionantes murales."
        },
        Cuautla: {
            telefono: "+5513168143",
            descripcion: "Construcción de 3 niveles y ubicación estratégica a 3 minutos del centro de Cuautla, cerca de nosotros encontrarás cafeterías, restaurantes, bancos, escuelas y oficinas gubernamentales. La calle en donde nos ubicamos es de tránsito local y bastante amplia por lo que cuenta con suficiente espacio para estacionarse, a su vez, la calle cuenta con 16 cámaras vecinales y 4 cámaras de seguridad propias de Larghetto en el exterior y 20 cámaras al interior, así como alarma vecinal controlada vía control remoto para garantizar la seguridad de todos dentro y fuera del campus. En transporte público, solo necesitaras caminar aproximadamente 40 metros para llegar a la Avenida Gabriel Tepepa. Disfruta de salones equipados con aire acondicionado, tratamiento acústico y Smart Tv, que elevan tu clase a un mayor grado de confort, a su vez, áreas de descanso y sociales, así como juegos de mesa, futbolito de salón y una gran explanada para presentaciones con 280 metros cuadrados al aire libre."
        },
        Centro: {
            telefono: "+7775341941",
            descripcion: "Ubicado en el corazón de Cuernavaca, Morelos, a unos pasos de Avenida Morelos y el emblemático Jardín Borda, cerca de nosotros encontrarás restaurantes, cafeterías, spas, salones de belleza, supermercados, bancos y mucho más. La calle donde nos ubicamos es una calle angosta en donde es difícil estacionarse, sin embargo, existen 6 estacionamientos públicos en la periferia de la manzana (independientes y ajenos a Larghetto). Si te trasladas en transporte público, existen un sinfín de rutas que te llevarán a tu destino sin importar la distancia. El campus cuenta con 2 cámaras de seguridad al exterior y alarma vecinal controlada vía remota, a su vez, cuenta con 18 cámaras de seguridad a su interior y 6 micrófonos grabadores, así como reja de seguridad automática para su acceso. Disfruta de salones equipados con aire acondicionado, tratamiento acústico, Smart tv y pisos laminados, que elevarán tu comodidad dentro del campus, a su vez, contamos con áreas sociales y de espera, así como una basta colección de libros a tu disposición."
        },
        Coyoacán: {
            telefono: "+525521048568",
            descripcion: "Ubicado justo frente a la Terminal Central de Autobuses del Sur y la terminal de metro Taxqueña, cerca del campus encontrarás centros comerciales, salones de belleza, bancos, parques, supermercados, y mucho más. Al encontrarnos en avenida principal, es complicado estacionarse, sin embargo, en las calles aledañas existe mucho espacio para estacionarse, además a 200 metros del campus, en avenida Cerro de las Torres, se encuentra un supermercado de cadena internacional con estacionamiento, a su vez frente a dicho supermercado se encuentra un estacionamiento público. Si vienes en transporte público encontrarás el paradero de trolebús justo frente al campus, a su vez, cruzando la avenida, encontrarás la terminal de metro Taxqueña. Disfruta de salones perfectamente equipados con todo lo necesario, Smart tv de 60\", tratamiento acústico de alta densidad, área de espera, juegos de mesa, futbolito de salón, ventanales con tratamiento acústico, espejos de gran formato y pisos laminados."
        }
    };

    return (
        <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
            <Dialog.Backdrop
                transition
                className="fixed inset-0 bg-gray-900/85 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-zinc-950 text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg data-closed:sm:translate-y-0 data-closed:sm:scale-95"
                    >
                        <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto lg:flex size-12 shrink-0 items-center justify-center rounded-full bg-gray-900 sm:mx-0 sm:size-10 sm:hidden hidden ">
                                    <HomeModernIcon aria-hidden="true" className="size-6 text-gray-400" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold text-gray-400 mt-0 lg:mt-1 sm:mt-0 flex justify-between">
                                        <div className='w-full'>
                                            Campus {campus}
                                        </div>
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500 text-justify">
                                            {infoPorCampus[campus].descripcion}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-900 px-4 py-3 flex sm:px-6">
                            <div className='bg-gray-900 w-[35%] flex space-between flex-row justify-between pr-4 sm:mt-3 lg:mt-0 mt-3'>
                                <a
                                    href={`https://wa.me/${infoPorCampus[campus].telefono}?text=Hola, me gustaría obtener más información sobre los cursos`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-gray-400 transition-transform duration-300 hover:scale-110 cursor-pointer self-center'
                                >
                                    <FaWhatsapp className='w-6 h-6 text-gray-400' />
                                </a>
                                <a
                                    href="https://www.facebook.com/LarghettoRMA/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-gray-400 transition-transform duration-300 hover:scale-110 cursor-pointer self-center'
                                >
                                    <FaFacebookF className='w-6 h-5 text-gray-400' />
                                </a>
                                <a
                                    href="https://www.instagram.com/larghetto_academia/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-gray-400 transition-transform duration-300 hover:scale-110 cursor-pointer self-center'
                                >
                                    <FaInstagram className='w-6 h-6 text-gray-400' />
                                </a>
                            </div>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setIsOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-zinc-950 px-3 py-2 text-sm font-semibold text-gray-400 ring-2 shadow-xs ring-gray-400 ring-inset hover:bg-gray-800 sm:mt-0"
                            >
                                Cerrar
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}
