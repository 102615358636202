import React, {useState} from 'react'
import ModalOfertaEducativa from './ModalOfertaEducativa'

export default function Pruebas() {

    const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='h-full w-full'>
      <button onClick={()=>setIsOpen(true)}>asdasd</button>

      <ModalOfertaEducativa isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div>
  )
}
