import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect, useState } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)


const features = [
    {
        name: 'Oasis de Tranquilidad',
        description:
            'Un refugio dentro del ajetreo del trabajo, donde el silencio y la paz reinan. Un espacio con una atmósfera serena y libre de distracciones, ideal para concentrarse y realizar tareas con máxima eficiencia.',
        icon: MusicalNoteIcon,
    },
    {
        name: 'Arte que Inspira',
        description: 'Un espacio de trabajo que no solo sea funcional, sino también un festín para los ojos. Murales vibrantes y llenos de color',
        icon: PaintBrushIcon,
    },
    {
        name: 'Un oasis verde dentro de la oficina, donde puedes conectar con la naturaleza y disfrutar de sus beneficios',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
        icon: GlobeAmericasIcon,
    },
]


export default function FeatureSectionCentroCopy({ setCampus, setIsOpen }) {
    const [imagenes, setImagenes] = useState([]);
    
        useEffect(() => {
            const cargarImagen = async (nombre) => {
                try {
                    const response = await fetch(`http://104.237.128.187:3001/api/uploads/imageCampus/${nombre}`, {
                        method: 'GET'
                    });
                    
                    if (response.ok) {
                        const blob = await response.blob();
                        const imageUrl = URL.createObjectURL(blob);
                        setImagenes((imagenes) => [...imagenes, { [nombre]: imageUrl }]);
                        console.log(imagenes)
                    }
                } catch (err) {
                    console.error('Error cargando imagen:', err);
                }
            };
    
            // Solo cargamos 2 imágenes
            Promise.all([
                cargarImagen('web2.jpg'),
                cargarImagen('web3.jpg')
            ]);
    
            // Cleanup function para liberar URLs
            return () => {
                imagenes.forEach(url => URL.revokeObjectURL(url));
            };
        }, []);
    return (
        <div className="overflow-hidden bg-gradient-to-b3 h-auto sm:h-auto lg:h-[100vh] md:h-[100vh] w-[100vw] py-10" id='campusBuga'>

            <div className=' w-auto h-full mx-6 lg:mx-6 md:flex sm:hidden lg:flex hidden flex-row-reverse'>

                <div className='w-0 lg:w-1/6 md:w-0 sm:w-0'>
                </div>
                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-col'>
                    <div className=' h-full p-3 relative'>
                        <img
                            src={imagenes.find(img => Object.keys(img)[0] === `web2.jpg`)?.[`web2.jpg`] || ""}
                            alt="Product screenshot"
                            className="w-full h-full imageGlow2"

                        />
                        <Link onClick={() => {
                            setCampus('Centro')
                            setIsOpen(true)
                        }}  className="absolute top-3 right-3 w-0 h-0 border-l-[100px] border-l-transparent border-t-[100px] border-t-black hover:scale-110 transform transition duration-300">
                        </Link>
                        <Link onClick={() => {
                            setCampus('Centro')
                            setIsOpen(true)
                        }}  className='absolute top-5 right-7 text-gray-300 italic'>Saber</Link>
                        <Link onClick={() => {
                            setCampus('Centro')
                            setIsOpen(true)
                        }}  className='absolute top-9 right-7 text-gray-300 italic'>Más</Link>
                    </div>
                </div>

                <div className=' h-full w-2/6 lg:w-2/6 md:w-3/6 sm:w-3/6 flex flex-row'>

                    <div className=' w-full flex flex-col-reverse'>
                        <div className=' h-3/6 py-3'>
                            <MapsTemplate campus={"centro"} />
                        </div>
                        <div className=' h-2/6 imageGlow2'>
                            <img src={imagenes.find(img => Object.keys(img)[0] === `web3.jpg`)?.[`web3.jpg`] || ""} alt=""
                                className='h-full p-0 w-full' />
                        </div>
                        <div className=' h-1/6 grid items-center'>
                            <div>

                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Cuernavaca</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Centro</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Movil */}
            <div className=' w-auto h-full mx-6 lg:mx-6 md:hidden sm:flex lg:hidden flex flex-col'>
                <div>

                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Cuernavaca</h2>
                    <p className="mt-0 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Centro</p>
                    
                </div>
                <Link onClick={() => {
                            setCampus('Centro')
                            setIsOpen(true)
                        }}  className='mt-3 p-2 bg-gray-900 text-white'>Saber más</Link>
                <div className='py-3'>
                    <img
                        src={imagenes.find(img => Object.keys(img)[0] === `web2.jpg`)?.[`web2.jpg`] || ""}
                        alt="Product screenshot"
                        className="w-full h-full imageGlow2"

                    />
                </div>
                <div className='w-full h-80'>
                    <MapsTemplate campus={"centro"} />
                </div>

            </div>

        </div>


    )
}
