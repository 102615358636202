import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { AcademicCapIcon, HomeModernIcon } from '@heroicons/react/24/outline'

export default function ModalOfertaEducativa({ isOpen, setIsOpen, descripcion="hola",titulo="Canto" }) {

    return (
        <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
            <Dialog.Backdrop
                transition
                className="fixed inset-0 bg-gray-900/85 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Dialog.Panel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-zinc-950 text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg data-closed:sm:translate-y-0 data-closed:sm:scale-95"
                    >
                        <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto lg:flex size-12 shrink-0 items-center justify-center rounded-full bg-gray-900 sm:mx-0 sm:size-10 sm:hidden hidden ">
                                    <AcademicCapIcon aria-hidden="true" className="size-6 text-gray-400" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold text-gray-400 mt-0 lg:mt-1 sm:mt-0 flex justify-between">
                                        <div className='w-full'>
                                            {titulo}
                                        </div>
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500 text-justify">
                                            {descripcion}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-900 px-4 py-3 flex sm:px-6">
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setIsOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-zinc-950 px-3 py-2 text-sm font-semibold text-gray-400 ring-2 shadow-xs ring-gray-400 ring-inset hover:bg-gray-800 sm:mt-0"
                            >
                                Cerrar
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}
